<template>
  <b-modal
    ref="add-media-modal-1"
    centered
    hide-footer
    hide-header
    :disabled="spinner"
    @hide="onModalHide"
  >
    <div class="add-media-modal d-flex justify-content-center align-content-center align-items-center">
      <b-row style="display: block ">
        <div class="closediv">
          <b-button
            variant="transparent"
            class="closebtn"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
            />
          </b-button>
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-2 mb-0">
          <p class="font-weight-bolder heading1">
            Add Media
          </p>
        </div>
        <div class="d-inline-block mb-1 mt-0 d-flex justify-content-center align-content-center align-items-center">
          <div
            id="form"
            class="form pb-2 w-100 "
          >
            <validation-observer ref="addMediaForm">
              <b-form
                class="p-0"
                @submit.prevent
              >
                <div>
                  <b-form-group
                    text-bolder
                    label="Select Type"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Select Type"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedTitle"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option"
                        class="label"
                        :clearable="false"
                        @input="selectChangeHandler"
                      />
                      <small class="text-danger ">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="selectedTitle && selectedTitle.title && selectedTitle.title === 'Image'"
                  class="mb-1"
                >
                  <b-form-group
                    text-bolder
                    label="Duration (seconds)"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Image Duration"
                    >
                      <b-form-input
                        id="number"
                        v-model="imageDuration"
                        type="number"
                        class="enable-spinners"
                        placeholder="Please enter Image Duration"
                        min="5"
                        max="36000"
                        @input="validateDuration(imageDuration)"
                      />
                      <small class="text-danger">{{ errors.length > 0 ? errors[0] : errorMessage }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- Video uploading -->
                <div v-if="selectedTitle && selectedTitle.title && selectedTitle.title === 'Video'">
                  <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center position-relative mt-1">
                    <div
                      class="upload-video-image-div"
                      @dragover.prevent
                      @drop.prevent
                    >
                      <div @drop="dragFileVideo">
                        <span class=" d-flex justify-content-center align-content-center">
                          <b-img
                            :src="fileData.file? require('@/assets/images/simiicons/Video(1).svg') : require('@/assets/images/simiicons/Video(2).svg')"
                            alt="data.json"
                          />
                        </span>
                        <div
                          v-if="!fileSrc.videoSrc"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="'Drag & drop Video here or'"
                          class="text-truncate"
                        >
                          <small
                            class="font-small-1 text-truncate"
                          >Drag & drop Video here or</small>
                        </div>
                        <div
                          v-else
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="fileData.file ? fileData.file.name : null"
                          class="text-truncate"
                        >
                          <strong
                            class=" font-small-1"
                          >{{ fileData.file?fileData.file.name : '' }}</strong>
                        </div>
                        <b-form-group
                          text-bolder
                          class="label"
                        >
                          <validation-provider
                            name="video"
                          >
                            <b-form-file
                              id="video"
                              v-model="fileData.file"
                              :hidden="true"
                              plain
                              class="pl-12"
                              accept="video/*"
                              @change="uploadVideo($event)"
                            />
                            <b-link
                              v-if="fileSrc.videoSrc"
                              class="d-flex justify-content-center align-items-center font-small-2"
                              @click="removeVideo"
                            >
                              Remove
                            </b-link>
                            <b-link
                              v-else
                              class="d-flex justify-content-center align-items-center font-small-2"
                              @click="chooseVideoFiles"
                            >
                              Browse
                            </b-link>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                    <div
                      class="upload-thumbnail-div"
                      @dragover.prevent
                      @drop.prevent
                    >
                      <div
                        @drop="dragFileThumbnail"
                      >
                        <span class=" d-flex justify-content-center align-content-center">
                          <b-img
                            :src="fileSrc.thumbnailSrc ? fileSrc.thumbnailSrc :require('@/assets/images/simiicons/thumbnail.svg')"
                            alt="data.json"
                            :class="fileSrc.thumbnailSrc ? 'imageSrc': null"
                          />
                        </span>
                        <div
                          v-if="!fileSrc.thumbnailSrc"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="'Drag & drop Thumbnail here or'"
                          class="text-truncate"
                        >
                          <small
                            class="font-small-1 text-truncate"
                          >Drag & drop Thumbnail here or</small>
                        </div>
                        <div
                          v-else
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="fileData.thumbnail ? fileData.thumbnail.name : null"
                          class="text-truncate d-flex justify-content-center align-items-center"
                        >
                          <strong
                            class="font-small-1"
                          >{{ fileData.thumbnail? fileData.thumbnail.name: '' }}</strong>
                        </div>
                        <b-form-group
                          text-bolder
                          class="label"
                        >
                          <b-form-file
                            id="thumbnailRef"
                            v-model="fileData.thumbnail"
                            accept=".jpg, .png, .gif"
                            :hidden="true"
                            plain
                            @change="uploadThumbnail($event)"
                          />
                          <b-link
                            v-if="fileSrc.thumbnailSrc"
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="removeThumbnail"
                          >
                            Remove
                          </b-link>
                          <b-link
                            v-else
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="thumbnailClick"
                          >
                            Browse
                          </b-link>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- upload Image-->
                <div
                  v-if="selectedTitle && selectedTitle.title && selectedTitle.title === 'Image'"
                  class="d-flex justify-content-center align-items-center imageDiv"
                >
                  <div
                    class="upload-image-div"
                    @dragover.prevent
                    @drop.prevent
                  >
                    <div
                      class="pt-1"
                      @drop="dragFileImage"
                    >
                      <span class=" d-flex justify-content-center align-content-center">
                        <b-img
                          :src="fileSrc.imageSrc ? fileSrc.imageSrc : require('@/assets/images/simiicons/Image(2).svg')"
                          alt="data.json"
                          :class="fileSrc.imageSrc ? 'imageSrc': null"
                        />
                      </span>
                      <small
                        v-if="!fileSrc.imageSrc"
                        class="text-nowrap font-small-1 d-flex justify-content-center align-items-center"
                      >Drag & drop Images here or</small>
                      <div
                        v-else
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="fileData.file ? fileData.file.name: null"
                        class="text-truncate"
                      >
                        <strong
                          class="font-small-1 d-flex justify-content-center align-items-center"
                        >{{ fileData.file ? fileData.file.name: '' }}</strong>
                      </div>
                      <b-form-group
                        text-bolder
                        class="label"
                      >
                        <validation-provider
                          name="image"
                        >
                          <b-form-file
                            id="image"
                            v-model="fileData.file"
                            :hidden="true"
                            plain
                            class="pl-12"
                            accept="image/*"
                            @change="uploadImage($event)"
                          />
                          <b-link
                            v-if="fileSrc.imageSrc"
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="removeImage"
                          >
                            Remove
                          </b-link>
                          <b-link
                            v-else
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="chooseImage"
                          >
                            Browse
                          </b-link>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-center"
                >
                  <b-button
                    class="button w-50 mt-1"
                    variant="primary"
                    block
                    :disabled="spinner"
                    @click="saveFileToArray"
                  >
                    Add
                  </b-button>
                </div>
                <div
                  style="height: 120px;background-color: #F2FFFF;"
                  class="scrollable-container mt-1 rounded"
                >
                  <div
                    v-if="multipleFiles.file.length <= 0"
                    class="d-flex justify-content-center align-items-center h-100"
                  >
                    <span>No Media Added Yet</span>
                  </div>
                  <b-row class="m-0">
                    <div
                      v-for="(image,index) in multipleFiles.imageSrc"
                      :key="index"
                      class="d-flex"
                    >
                      <b-media
                        vertical-align="center"
                        class="media-file text-truncate"
                      >
                        <template #aside>
                          <div style="position: relative">
                            <b-img
                              :src="image"
                              class="image"
                              rounded
                            />
                            <span class="cross">
                              <b-img
                                :src="require('@/assets/images/simiicons/close.svg')"
                                rounded
                                alt="data.json"
                                class="cursor-pointer"
                                @click="deleteMediaFromAddMedia(index)"
                              />
                            </span>
                          </div>
                        </template>
                        <b-row>
                          <b-col class="col-12 w-2">
                            <span
                              class="text float-left text-truncate"
                            >
                              {{ multipleFiles.file[index].name }}
                            </span>
                          </b-col>
                          <b-col class="col-12">
                            <div class="float-left">
                              {{ multipleFiles.duration[index] }} sec
                              <b-img
                                width="15px"
                                height="15px"
                                :src="returnSrc(index)"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-media>
                    </div>
                  </b-row>
                </div>
                <div class="small px-2">
                  {{ errorMsg ? errorMsg: null }}
                </div>
                <div
                  class="d-flex mb-2 mx-2 d-flex justify-content-center"
                >
                  <b-button
                    type="submit"
                    class="button mt-1 w-50"
                    variant="primary"
                    block
                    :disabled="spinner"
                    @click="addMedia"
                  >
                    <div
                      v-if="spinner"
                      class="spinner"
                    >
                      <!-- <b-spinner
                        small
                      /> -->
                    </div>
                    Submit
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-row>
    </div>
    <div
      v-if="spinner"
      class="modal-overlay"
    >
      <b-spinner label="Loading..." />
    </div>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import {
  BRow,
  BFormInput,
  BButton,
  BForm,
  BFormGroup,
  BFormFile,
  BImg,
  BLink,
  BSpinner,
  VBTooltip,
  BMedia,
  BCol,
} from 'bootstrap-vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import MediaType from '@/common/enums/mediaTypeEnum'
import imagePicIcon from '@/assets/images/simiicons/Image(1).svg'
import VideoPicIcon from '@/assets/images/simiicons/Video(1).svg'
import {
  // showErrorMessage,
  showToast,
  getFormDataFromJSONImage,
} from '@/common/global/functions'

export default {
  components: {
    BRow,
    BFormInput,
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BImg,
    BLink,
    BSpinner,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      typesOfImage: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
      typesOfVideo: ['video/mp4', 'video/3gpp', 'video/mov', 'video/mkv', 'video/x-matroska', 'video/quicktime'],
      fileData: {
        name: '',
        file: null,
        thumbnail: null,
        imageSrc: null,
      },
      multipleFiles: {
        file: [],
        thumbnail: [],
        duration: [],
        imageSrc: [],
      },
      fileSrc: {
        imageSrc: null,
        thumbnailSrc: null,
        videoSrc: null,
      },
      errorMsg: null,
      uploadPercentage: 0,
      spinner: false,
      videoData: '',
      selectedTitle: { title: 'Video' },
      option: [{ title: 'Video' }, { title: 'Image' }],
      AccountTypes,
      // validation
      required,
      imageDuration: null,
      errorMessage: '',
      isValidImageDuration: false,
      is_image: false,
      MediaType,
      tempVideoDuration: '',
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  methods: {
    saveFileToArray() {
      if (this.selectedTitle.title === MediaType.AddMediaTypeImage) {
        if ((this.fileData.imageSrc === null || this.fileData.imageSrc === undefined) && this.imageDuration === null) {
          this.errorMsg = 'Please add Image to add media'
        } else if (this.fileData.imageSrc !== null && this.imageDuration === null) {
          this.errorMsg = 'Please add Duration to add media'
        } else if ((this.fileData.imageSrc === null || this.fileData.imageSrc === undefined) && this.imageDuration !== null) {
          this.errorMsg = 'Please upload Image to add media'
        } else if (!this.isValidImageDuration) {
          this.errorMsg = 'Duration should in between 5 to 36000'
        } else {
          this.multipleFiles.file.push(this.fileData.file)
          this.multipleFiles.thumbnail.push(this.fileData.file)
          this.multipleFiles.duration.push(Number(this.imageDuration))
          this.multipleFiles.imageSrc.push(this.fileData.imageSrc)
          this.errorMsg = ''
          this.clearTempValues()
        }
      } else if (this.selectedTitle.title === MediaType.AddMediaTypeVideo) {
        if (this.fileSrc.videoSrc === null && this.fileSrc.thumbnailSrc === null) {
          this.errorMsg = 'Please add Video and Thumbnail to add media'
        } else if (this.fileSrc.videoSrc !== null && this.fileSrc.thumbnailSrc === null) {
          this.errorMsg = 'Please upload Thumbnail to add media'
        } else if (this.fileSrc.videoSrc === null && this.fileSrc.thumbnailSrc !== null) {
          this.errorMsg = 'Please upload Video to add media'
        } else {
          this.multipleFiles.file.push(this.fileData.file)
          this.multipleFiles.thumbnail.push(this.fileData.thumbnail)
          this.multipleFiles.duration.push(this.tempVideoDuration)
          this.multipleFiles.imageSrc.push(this.fileSrc.thumbnailSrc)
          this.clearTempValues()
        }
      }
    },
    validateDuration(time) {
      if (time < 5 || time > 36000) {
        this.errorMessage = 'Duration should be in between 05 - 36000'
        this.isValidImageDuration = false
      } else {
        this.errorMessage = ''
        this.isValidImageDuration = true
      }
    },
    async addMedia() {
      if (this.multipleFiles.file.length > 0) {
        this.spinner = true
        await this.uploadAllFiles()
        await this.$emit('getAllMediaStorage')
        this.fileData = {}
        this.imageDuration = null
        this.errorMessage = ''
        this.isValidImageDuration = false
      } else {
        this.errorMsg = 'Please Add Image or Video to upload media'
      }
    },
    async uploadAllFiles() {
      const success = []
      const error = []
      const promises = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.multipleFiles.file.length; i++) {
        const formData = getFormDataFromJSONImage(this.multipleFiles, i)

        promises.push(new Promise(resolve => {
          this.addMediaStorage(formData).then(() => {
            success.push('Success')
          }).catch(e => {
            error.push(e)
            resolve('')
          }).finally(() => resolve(''))
        }))
      }

      await Promise.all(promises)
      if (success.length && error.length) {
        showToast('Add Media', 'Media has been added successfully!', 'success')
        showToast('Add Media', `${error[0]}`, 'danger')
      } else if (success.length && !error.length) {
        showToast('Add Media', 'Media has been added successfully!', 'success')
      } else {
        showToast('Add Media', `${error[0]}`, 'danger')
      }
      this.spinner = false
      await this.hide()
      this.clearMultipleFilesValues()
    },

    async  addMediaStorage(formData) {
      this.uploadPercentage = 0
      if (this.userRole === this.AccountTypes.USER) {
        await this.$axios
          .post('media',
            formData, {
              onUploadProgress: event => {
                this.uploadPercentage = Math.round((event.loaded * 100) / event.total)
              },
            })
      } if (this.userRole === this.AccountTypes.ADMIN) {
        await this.$axios
          .post('admin/media',
            formData, {
              headers: { userHasGroupId: this.$route.params.userId },
              onUploadProgress: event => {
                this.uploadPercentage = Math.round((event.loaded * 100) / event.total)
              },
            })
      }
    },
    show() {
      this.uploadPercentage = 0
      this.fileData = {}
      this.errorMsg = null
      this.fileSrc.imageSrc = null
      this.fileSrc.thumbnailSrc = null
      this.fileSrc.videoSrc = null
      this.$refs['add-media-modal-1'].show()
    },
    clearMultipleFilesValues() {
      this.multipleFiles.file = []
      this.multipleFiles.thumbnail = []
      this.multipleFiles.imageSrc = []
      this.multipleFiles.duration = []
    },
    clearValues() {
      this.fileData = {}
      this.imageDuration = null
      this.errorMessage = ''
      this.isValidImageDuration = false
      this.is_image = false
      this.clearMultipleFilesValues()
    },
    clearTempValues() {
      this.fileData.file = null
      this.fileData.thumbnail = null
      this.fileData.imageSrc = null
      this.fileSrc.imageSrc = null
      this.fileSrc.thumbnailSrc = null
      this.fileSrc.videoSrc = null
      this.tempVideoDuration = null
      this.imageDuration = null
    },
    onModalHide() {
      this.hide()
    },
    hide() {
      this.clearValues()
      this.$refs['add-media-modal-1'].hide()
    },
    selectChangeHandler() {
      this.errorMsg = null
      this.videoData = null
      this.fileData.file = null
      this.fileData.thumbnail = null
      this.removeImage()
      this.removeVideo()
      this.removeThumbnail()
    },
    chooseVideoFiles() {
      document.getElementById('video').click()
    },
    chooseImage() {
      document.getElementById('image').click()
    },
    thumbnailClick() {
      document.getElementById('thumbnailRef').click()
    },
    removeThumbnail() {
      this.fileSrc.thumbnailSrc = null
      this.fileData.thumbnail = null
      this.errorMsg = null
    },
    removeImage() {
      this.clearTempValues()
      this.errorMsg = null
    },
    removeVideo() {
      this.clearTempValues()
      this.errorMsg = null
    },
    uploadThumbnail(event) {
      this.errorMsg = null
      const obj = event.target.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.thumbnailSrc = URL.createObjectURL(obj)
          this.fileData.thumbnail = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.thumbnail = null
        }
      } else {
        this.errorMsg = 'Please upload Image for Thumbnail!'
        this.fileData.thumbnail = null
        this.fileSrc.thumbnailSrc = null
      }
    },
    dragFileThumbnail(e) {
      this.errorMsg = null
      const obj = e.dataTransfer.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.thumbnailSrc = URL.createObjectURL(obj)
          this.fileData.thumbnail = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.thumbnail = null
        }
      } else {
        this.errorMsg = 'Please upload Image for Thumbnail!'
        this.fileData.thumbnail = null
        this.fileSrc.thumbnailSrc = null
      }
    },
    dragFileImage(e) {
      this.errorMsg = null
      const obj = e.dataTransfer.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.imageSrc = URL.createObjectURL(obj)
          this.fileData.imageSrc = URL.createObjectURL(obj)
          this.fileData.file = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.file = null
        }
      } else {
        this.errorMsg = 'Please upload Image having type jpeg, png,jpg!'
        this.fileData.file = null
        this.fileSrc.imageSrc = null
      }
    },
    uploadImage(event) {
      this.errorMsg = null
      this.randomKey = Math.random()
      const [obj] = event.target.files
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.imageSrc = URL.createObjectURL(obj)
          this.fileData.imageSrc = URL.createObjectURL(obj)
          this.fileData.file = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.file = null
        }
      } else {
        this.errorMsg = 'Please upload Image having type jpeg, png, jpg!'
        this.fileData.file = null
        this.fileSrc.imageSrc = null
      }
    },
    dragFileVideo(e) {
      this.errorMsg = null
      const [obj] = e.dataTransfer.files
      const { size, type } = obj
      const video = document.createElement('video')
      let duration = ''
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        duration = video.duration
        this.tempVideoDuration = (Math.round(video.duration))
        if (this.typesOfVideo.includes(type)) {
          if (size < '104857600 ' && duration >= 5) {
            this.fileSrc.videoSrc = URL.createObjectURL(obj)
            this.fileData.file = obj
            this.errorMsg = null
          } else {
            this.errorMsg = 'Max size of file should be 100MB'
            this.fileData.file = null
          }
        } else {
          this.errorMsg = 'Please upload Video having type mp4, 3gpp, mov,mkv,x-matroska,quicktime!'
          this.fileData.file = null
          this.fileSrc.videoSrc = null
        }
      }
      video.src = URL.createObjectURL(obj)
    },
    async uploadVideo(event) {
      const [obj] = event.target.files
      const { size, type } = obj
      const video = document.createElement('video')
      let duration = ''
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        duration = video.duration
        this.tempVideoDuration = (Math.round(video.duration))
        if (this.typesOfVideo.includes(type)) {
          if (size < 104857600 && duration >= 5) {
            this.fileSrc.videoSrc = URL.createObjectURL(obj)
            this.fileData.file = obj
            this.errorMsg = null
          } else {
            this.errorMsg = 'Max size of file should be 100MB or min 5sec!'
            this.fileData.file = null
          }
        } else {
          this.errorMsg = 'Please upload Video having type mp4, 3gpp, mov,mkv,x-matroska,quicktime!'
          this.fileData.file = null
          this.fileSrc.videoSrc = null
        }
      }
      video.src = URL.createObjectURL(obj)
    },
    deleteMediaFromAddMedia(index) {
      this.multipleFiles.file.splice(index, 1)
      this.multipleFiles.duration.splice(index, 1)
      this.multipleFiles.thumbnail.splice(index, 1)
      this.multipleFiles.imageSrc.splice(index, 1)
    },
    returnSrc(index) {
      if (this.multipleFiles.file[index].type === MediaType.AddMediaTypeImageType) {
        return imagePicIcon
      }
      return VideoPicIcon
    },
  },
}
</script>
<!--<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>-->
<style lang="scss">
.add-media-modal {
  .addprofile {
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .title {
    /*width: 100%;*/
    height: 44px;
    background-color: #f8f8f8;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 12px;
  }

  .form {
    padding-inline: 10px;
    min-width: 320px !important;
    max-width: 380px !important;
    @media (max-width: 640px) {
      min-width: 320px !important;
      max-width: 320px !important;
    }
    @media (max-width: 560px) {
      min-width: 280px !important;
      max-width: 280px !important;
    }
  }

  .small {
    font-size: 12px;
    line-height: 22px;
    color: #cf0000;
    font-weight: 500;
    font-family: "Montserrat";
    text-align: center;
    margin-top: 10px;
  }

  .choose {
    margin: auto;
    margin-top: 15px;
    display: flex;
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }

  .choose:focus {
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }

  .select {
    min-width: 380px;
    height: 38px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #d8d6de;
    margin: 5px
  }

  .head {
    font-size: 14px;
    line-height: 24px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .label {
    font-size: 14px;
    color: #5e5873;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .thumbnaildiv {
    width: 20px;
    height: auto;
  }

  .thumbImg {
    width: 60px;
    height: auto;
  }

  .upload-video-image-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding: 14px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
    @media (max-width: 640px) {
      max-width: 140px !important;
      min-width: 140px  !important;
    }
    @media (max-width: 600px) {
      max-width: 120px !important;
      min-width: 120px  !important;
    }
  }
  .upload-image-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding: 14px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
  }

  .loadedData {
    border: solid #8080803b;
    border-radius: 2px;
  }

  .mediaImage {
    width: 40px;
    height: 40px;
  }
  .media-file{
    width: 151px;
    height: 60px;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cross{
    position: absolute;
    top: -7px;
    left: -5px;
    color: black;
  }
  .image {
    width: 32px !important;
    height: 32px !important;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
  }

  .upload-thumbnail-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 26px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
    @media (max-width: 640px) {
      max-width: 140px !important;
      min-width: 140px  !important;
    }
    @media (max-width: 600px) {
      max-width: 120px !important;
      min-width: 120px  !important;
    }
  }

  .imageSrc {
    width: 25px;
    height: 25px;
  }
  .imageDiv {
    min-width: 360px;
    @media (max-width: 640px) {
      min-width: 0px !important;
      max-width: 340px !important;
    }
  }
  .scrollable-container {
  max-height: 120px;
  overflow-y: auto;
}
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it sits above the modal content */
}

.enable-spinners::-webkit-inner-spin-button,
.enable-spinners::-webkit-outer-spin-button {
appearance: menulist-button !important;
}
</style>
